.footer {

  width: 100%;
  background: black;
  box-sizing: border-box;

  &.LINKIN-AD,&.SSWW-AD {
    background: #085673;
  }

  &-container {
    /*height: 380px;*/
    //margin: 0 auto;

    .body {
      width: 1200px;
      margin: 0 auto;

      display: flex;
      flex-flow: nowrap row;
      justify-content: space-between;
      align-items: flex-start;

      box-sizing: border-box;
      padding: 80px 0 40px;

      .item {

        width: 500px;

        a {
          text-decoration: none;
        }

        &-title {
          font-size: 20px;

          font-weight: 600;
          line-height: 28px;
          margin-bottom: 34px;
          color: rgba(255, 255, 255, 1);

          a {
            color: rgba(255, 255, 255, 1);
            text-decoration: none;
          }
        }

        p {
          margin: 0;
          font-size: 14px;

          font-weight: 600;
          color: rgba(255, 255, 255, 0.6);
          line-height: 20px;
          margin-top: 20px;
        }

        img {
          width: 130px;
          height: auto;
        }

        span {

          font-weight: 600;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.6);
          text-align: center;
          display: block;
          margin-top: 16px;
        }

      }
    }

    .extra {

      a {
        color: rgba(255, 255, 255, 0.6);
      }

      padding: 26px 0;

      font-weight: 400;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      text-align: center;
      line-height: 22px;

      img {
        width: auto;
        height: 14px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 7px;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }

      p {
        margin: 0;
      }
    }
  }

}

