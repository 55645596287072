.logo {
  background: url("../common/assets/domain/BloomAd/image/logo@2x.png") no-repeat center;
  background-size: contain;

  &.RegoAD{
    background: url("../common/assets/domain/RegoAD/image/logo@2x.png") no-repeat center;
    background-size: contain;
  }
  &.PlusAD{
    background: url("../common/assets/domain/PlusAD/image/logo@2x.png") no-repeat center;
    background-size: contain;
  }
  &.CloudAd{
    background: url("../common/assets/domain/CloudAd/image/logo@2x.png") no-repeat center;
    background-size: contain;
  }
  &.LINKIN-AD{
    background: url("../common/assets/domain/LINKIN-AD/image/logo@2x.png") no-repeat center;
    background-size: contain;
  }
  &.SSWW-AD{
    background: url("../common/assets/domain/SSWW-AD/image/logo@2x.png") no-repeat center;
    background-size: contain;
  }
  &.AdEngine{
    background: url("../common/assets/domain/AdEngine/image/logo@2x.png") no-repeat center;
    background-size: contain;
  }
  &.XY-AD{
    background: url("../common/assets/domain/XY-AD/image/logo@2x.png") no-repeat center;
    background-size: contain;
  }
  &.XYAD{
    background: url("../common/assets/domain/XYAD/image/logo@2x.png") no-repeat center;
    background-size: contain;
  }
  &.AppPlusAd{
    background: url("../common/assets/domain/AppPlusAd/image/logo@2x.png") no-repeat center;
    background-size: contain;
  }
  &.BloomAd {
    background: url("../common/assets/domain/BloomAd/image/logo@2x.png") no-repeat center;
    background-size: contain;
  }
  &.xiyuads{
     background: url("../common/assets/domain/xiyuads/image/logo@2x.png") no-repeat center;
     background-size: contain;
  }
  &.wokaiad{
    background: url("../common/assets/domain/wokaiad/image/logo@2x.png") no-repeat center;
    background-size: contain;
  }
  &.WOSHI-AD {
    background: url("../common/assets/domain/WoshiAD/image/logo@2x.png") no-repeat center;
    background-size: contain;
  }


}