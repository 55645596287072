@import "loginFix";

.login-form-box {
    width: 100%;
    height: 100%;
    position: fixed;

    &.XY-AD,&.XYAD {
        .login-box {

            max-width: 620px;
            //max-height: 440px;
            width: 80%;
            height: 100%;

            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%, 0);
            z-index: 1;

            .login-content {
                position: relative;
                width: 100%;
                margin-top: 25%;

                .login-form {
                    box-sizing: border-box;
                    padding: 90px 118px;

                    .ant-input-lg {

                        height: 60px;
                        border: 1px solid #A4A4A4;
                        border-radius: 5px;
                    }
                }

                .login-form-container {

                    background: #FFFFFF;
                    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.1);
                    border-radius: 10px;

                }
            }
        }

        .login-footer {

            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 0;

            .footer {
                background: transparent;

                .footer-container {

                    .extra {
                        font-size: 16px;
                        font-weight: 400;
                        color: #ACACAC;
                        line-height: 24px;

                        a {
                            font-size: 16px;
                            font-weight: 400;
                            color: #ACACAC;
                            line-height: 24px;
                        }
                    }

                }
            }
        }
    }


}

@media (max-width: 768px) {

    .login-form-box {

        &.XY-AD,&.XYAD {
            .login-box {
                position: fixed;
                z-index: 1;
                height: auto;
                top: 0;
                transform: translate(-50%,0);

                .login-content {
                    //margin-top: -110%;
                    //overflow: scroll;

                    .login-form {
                        box-sizing: border-box;
                        padding: 45px 59px;

                        .ant-input-lg {
                            padding: 7px 16px;
                            height: 30px;
                            border: 1px solid #A4A4A4;
                            border-radius: 5px;
                            line-height: 30px;
                            font-size: 9px;

                            &::placeholder{
                                font-size: inherit;
                            }
                        }

                        .ant-legacy-form-item {
                            margin-bottom: 0;
                        }

                        &-button {
                            height: 30px;
                            font-size: 11px;
                            line-height: 30px;
                        }
                    }

                }

            }
        }

        //position: static;


    }

}
