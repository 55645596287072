@import "loginFix";

.login-form-box {
  width: 100%;
  height: 100%;
  position: fixed;

  .login-box {

    max-width: 400px;
    //max-height: 440px;
    width: 80%;
    height: 100%;

    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    z-index: 1;

    .login-content {
      position: relative;
      width: 100%;
      margin-top: 50%;
    }
  }

  .login-footer {

    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 0;

    .footer {
      background: transparent;
    }
  }
}

@media (max-width: 768px) {

  .login-form-box{

    //position: static;

    .login-box {
      position: fixed;
      z-index: 1;
      height: auto;
      top: 50%;
      transform: translate(-50%,-50%);

      .login-content{
        margin-top: -50%;
        //overflow: scroll;

      }

    }

  }

}
