.login-form {

  .ant-legacy-form-item {
    margin-bottom: 30px;
  }

  .ant-input-lg {
    padding: 14px 32px;
    line-height: 50px;
    height: 50px;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    border: none;

    font-size: 18px;
    font-weight: 600;
    color: rgba(51, 51, 51, 1);

    &::placeholder {
      font-size: 18px;
      font-weight: 400;
      color: rgba(173, 173, 173, 1);
    }
  }

  .ant-form-explain {

  }

  &-button {
    width: 100%;
    height: 60px;
    //background:rgba(8,86,115,1);
    //border-radius: 4px;
    border: none;

    font-size: 22px;
    font-weight: 600;
    //color:rgba(255,255,255,1);
    line-height: 60px;
    padding: 0;
  }

}
