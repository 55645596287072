@import "layoutsFix";

.user-layout-box {
  height: 100%;
}

.basice-layout-box {
  height: 100%;

  .layout-header {
    background: #fff;
    padding: 0;
    overflow: hidden;

    .ant-select {
      width: 300px;
    }

    @media screen and (max-width: 992px) {
      .ant-select {
        width: auto;
      }
    }

    .user-info {
      float: right;
      margin-right: 16px;

      .nickname {
        margin-right: 10px;
      }
    }
  }

  .layout-sider {
    .logo {
      height: 64px;
      font-size: 24px;
      color: #fff;
      font-weight: bold;
      margin-left: 24px;
      line-height: 64px;
      overflow: hidden;

      //background: url("../common/assets/domain/AdEngine/image/logo@2x.png") no-repeat center;
      //background-size: contain;
      margin-right: 24px;

    }
  }

  .layout-content {
    margin: 24px 16px;

    background: #fff;
    min-height: auto;

    .main-list-box {
      padding: 24px;
    }

    .main-detail-box {
      .detail-descriptions {
        padding: 24px;
      }

    }

    .split-line {
      margin: 20px 0;
      width: 100%;
      height: 1px;
      background-color: #f5f5f5;
    }
  }

  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #1890ff;
    }

    .logo {
      height: 32px;
      background: rgba(255, 255, 255, 0.2);
      margin: 16px;
    }
  }

}

.mobile-layout-box {
  height: 100%;

  .right-layout {
    position: absolute;
    width: 100%;
  }

  .layout-header {
    background: #fff;
    padding: 0;
    overflow: hidden;

    .ant-select {
      width: 300px;
    }

    @media screen and (max-width: 992px) {
      .ant-select {
        width: auto;
      }
    }

    .user-info {
      float: right;
      margin-right: 16px;

      .nickname {
        margin-right: 10px;
      }
    }
  }

  .layout-content {
    margin: 24px 16px;

    background: #fff;
    min-height: auto;

    .main-list-box {
      padding: 24px;
    }

    .main-detail-box {
      .detail-descriptions {
        padding: 24px;
      }

    }

    .split-line {
      margin: 20px 0;
      width: 100%;
      height: 1px;
      background-color: #f5f5f5;
    }

  }

}

.layout-box {
  background: white;
}
