.login-form-box {

    // default
    background: black url("../../common/assets/domain/BloomAd/image/bg@2x.png") no-repeat center;

    &.RegoAD {
        background: black url("../../common/assets/domain/RegoAD/image/bg@2x.png") no-repeat center;
    }

    &.PlusAD {
        background: black url("../../common/assets/domain/PlusAD/image/bg@2x.png") no-repeat center;
    }

    &.CloudAd {
        background: black url("../../common/assets/domain/CloudAd/image/bg@2x.png") no-repeat center;
    }

    &.LINKIN-AD {
        background: black url("../../common/assets/domain/LINKIN-AD/image/bg@2x.png") no-repeat center;
    }

    &.SSWW-AD {
        background: black url("../../common/assets/domain/SSWW-AD/image/bg@2x.png") no-repeat center;
    }

    &.AdEngine {
        background: black url("../../common/assets/domain/AdEngine/image/bg@2x.png") no-repeat center;
    }

    &.AppPlusAd {
        background: black url("../../common/assets/domain/AppPlusAd/image/bg@2x.png") no-repeat center;
    }

    &.BloomAd {
        background: black url("../../common/assets/domain/BloomAd/image/bg@2x.png") no-repeat center;
    }
    &.xiyuads {
        background: black url("../../common/assets/domain/xiyuads/image/bg@2x.png") no-repeat center;
    }
    &.wokaiad {
        background: black url("../../common/assets/domain/wokaiad/image/bg@2x.png") no-repeat center;
    }
    &.WOSHI-AD {
        background: black url("../../common/assets/domain/WoshiAD/image/bg@2x.png") no-repeat center;
    }

    &.XY-AD,&.XYAD {
        background: #FAFAFA;
        //background: #EA6E15 url("../../common/assets/domain/XY-AD/image/bg@2x.png") no-repeat center;
        background-size: cover;
    }

}

.login-title {

    &.XY-AD,&.XYAD {
        color: #F36C0A;
        border-bottom: 1px solid #F36C0A;
        margin-bottom: 0;
    }

    height: 63px;
    text-align: center;
    line-height: 63px;
    font-size: 24px;
    font-weight: bold;
    color: white;
    margin-bottom: 30px;
}

.login-logo {

    text-align: center;
    // default
    width: 280px;
    height: 36px;
    background: url("../../common/assets/domain/BloomAd/image/logo@2x.png") no-repeat center;
    background-size: cover;
    margin: 0 auto 80px;

    &.RegoAD {
        width: 223px;
        height: 30px;
        background: url("../../common/assets/domain/RegoAD/image/logo@2x.png") no-repeat center;
        background-size: cover;
        margin: 0 auto 80px;
    }

    &.PlusAD {
        width: 132px;
        height: 30px;
        background: url("../../common/assets/domain/PlusAD/image/logo@2x.png") no-repeat center;
        background-size: cover;
        margin: 0 auto 80px;
    }

    &.CloudAd {
        width: 142px;
        height: 30px;
        background: url("../../common/assets/domain/CloudAd/image/logo@2x.png") no-repeat center;
        background-size: cover;
        margin: 0 auto 80px;
    }

    &.LINKIN-AD {
        width: 296px;
        height: 40px;
        background: url("../../common/assets/domain/LINKIN-AD/image/logo@2x.png") no-repeat center;
        background-size: cover;
        margin: 0 auto 80px;
    }

    &.SSWW-AD {
        width: 141px;
        height: 30px;
        background: url("../../common/assets/domain/SSWW-AD/image/logo@2x.png") no-repeat center;
        background-size: cover;
        margin: 0 auto 80px;
    }

    &.AdEngine {
        width: 223px;
        height: 30px;
        background: url("../../common/assets/domain/AdEngine/image/logo@2x.png") no-repeat center;
        background-size: cover;
        margin: 0 auto 80px;
    }

    &.AppPlusAd {
        width: 199px;
        height: 34px;
        background: url("../../common/assets/domain/AppPlusAd/image/logo@2x.png") no-repeat center;
        background-size: cover;
        margin: 0 auto 80px;
    }

    &.BloomAd {
        width: 280px;
        height: 36px;
        background: url("../../common/assets/domain/BloomAd/image/logo@2x.png") no-repeat center;
        background-size: cover;
        margin: 0 auto 80px;


    }

    &.xiyuads {
        width: 235px;
        height: 51px;
        background: url("../../common/assets/domain/xiyuads/image/logo@2x.png") no-repeat center;
        background-size: cover;
        margin: 0 auto 80px;
    }

    &.wokaiad {
        width: 244px;
        height: 34px;
        background: url("../../common/assets/domain/wokaiad/image/logo@2x.png") no-repeat center;
        background-size: cover;
        margin: 0 auto 80px;
    }

    &.WOSHI-AD {
        width: 280px;
        height: 36px;
        background: url("../../common/assets/domain/WoshiAD/image/logo@2x.png") no-repeat center;
        background-size: cover;
        margin: 0 auto 80px;
    }

    &.XY-AD {
        width: 260px;
        height: 64px;
        background: url("../../common/assets/domain/XY-AD/image/logo@2x.png") no-repeat center;
        background-size: cover;
        margin: 0 auto 80px;
    }

    &.XYAD {
        width: 260px;
        height: 64px;
        background: url("../../common/assets/domain/XYAD/image/logo@2x.png") no-repeat center;
        background-size: cover;
        margin: 0 auto 80px;
    }

}

.login-form-button {

    // default
    background: rgba(64, 128, 255, 1);
    border-radius: 30px;

    &.RegoAD {
        background: rgba(39, 76, 211, 1);
        border-radius: 4px;
    }

    &.PlusAD, &.CloudAd {
        background: rgba(21, 125, 123, 1);
        border-radius: 4px;
    }

    &.LINKIN-AD, &.SSWW-AD {
        background: rgba(8, 86, 115, 1);
        border-radius: 4px;
    }

    &.AdEngine {
        background: rgba(8, 86, 115, 1);
        border-radius: 4px;
    }

    &.XY-AD,&.XYAD {
        background: #F36C0A;
        border-radius: 5px;
    }

    &.BloomAd {
        background: rgba(64, 128, 255, 1);
        border-radius: 30px;


    }
    &.xiyuads,&.wokaiad {
        background: rgba(64, 128, 255, 1);
        border-radius: 30px;
    }
    &.AppPlusAd {
        background: rgba(64, 128, 255, 1);
        border-radius: 4px;
    }

    &.WOSHI-AD {
        background: rgba(64, 128, 255, 1);
        border-radius: 4px;
    }

}

@media (max-width: 768px) {

    .login-title {

        &.XY-AD,&.XYAD {
            color: #F36C0A;
            border-bottom: 1px solid #F36C0A;
            margin-bottom: 0;
        }

        height: 31.5px;
        text-align: center;
        line-height: 31.5px;
        font-size: 12px;
        font-weight: bold;
        color: white;
        margin-bottom: 15px;
    }

    .login-logo {

        &.XY-AD {
            width: 130px;
            height: 32px;
            background: url("../../common/assets/domain/XY-AD/image/logo@2x.png") no-repeat center;
            background-size: cover;
            margin: 0 auto 40px;
        }

        &.XYAD {
            width: 130px;
            height: 32px;
            background: url("../../common/assets/domain/XYAD/image/logo@2x.png") no-repeat center;
            background-size: cover;
            margin: 0 auto 40px;
        }

    }

}